<template>
	<div class="camera-container text-center" style="cursor: pointer" @click="startMobilePwaCapture">
		<CameraIcon v-if="!useButton" color="#E2E6EB" :size="25" fill="#06263E" />
		<b-button v-if="useButton" variant="outline-primary"> <i class="icon-camera"></i> {{ labelButton }} </b-button>
		<CropCaptureModal
			ref="myCropPicture"
			v-bind="$props"
			v-model="isCropModalOpen"
			:base-url-img.sync="currentImg"
			@cropped="handleAutoCrop"
			@change="handleAutoCrop"
			@close="isCropModalOpen = false"
		/>
	</div>
</template>

<script>
import { Camera as CameraIcon } from 'lucide-vue';
import { Camera, CameraResultType } from '@capacitor/camera';
import { isNil, makeID, generateSecureId } from '~utils';
import mapProps from '@/shared/vuePropsMapper';
import { store } from '@/store';
import { reScalingImgWithCanvas } from '@/components/Packages/Captures/capture.utils';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

// import CropPicture from '@/components/CropPicture';
import CropCaptureModal from '@/components/Packages/Captures/components/CropCaptureModal';

export default {
	name: 'ProfilePicCaptureBtnCaptureModuleComponent',
	components: { CameraIcon, CropCaptureModal },
	mixins: [languageMessages, globalMixin],
	props: {
		...mapProps(['parentType', 'parentSubType', 'labelButton'], {
			type: String,
			required: false,
			default: null
			// validator: v => authorizedParentsTypes.includes(v)
		}),
		parentId: {
			type: [Number, String],
			required: false,
			default: null
		},
		useButton: {
			type: Boolean,
			default: false
		},
		cropType: {
			type: String,
			default: 'default',
			required: false,
			validator: (v) => ['avatar', 'default'].includes(v)
		}
	},
	data() {
		return {
			isStartMobilePwaCaptureLoading: false,
			instanceToken: makeID(5),
			currentImg: null,
			croppedImg: null,
			isCropModalOpen: false,
			isCropOpen: false,
			fileNameGenerated: null
		};
	},
	methods: {
		async startMobilePwaCapture() {
			this.$emit('click');
			// this.isStartMobilePwaCaptureLoading = true
			store.state.capturedImage = '';
			store.state.imageCropped = '';

			const photo = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				allowEditing: false,
				quality: 100
				// width: 400,
				// height: 400
			});

			this.currentImg = { img: photo.webPath };
			this.isCropModalOpen = true;
		},
   //  convertedToFile(imgData) {
   //    if (!isBase64(imgData)) return imgData;
   //    const base64Data = imgData.split(';base64,');
			// const ext = getExtensionFromBase64(imgData);
			// return b64toFile(base64Data[1], 'upload-file', ext);
   //  },
		async saveImg() {
			if (isNil(this.croppedImg)) return;
			let formData = new FormData();
      // const _f = this.convertedToFile(this.croppedImg);

      // just for make sure that we don't have an image more large than 2000px we make a reScaling
      const _f = await reScalingImgWithCanvas(this.croppedImg);
			formData.append('uploadimage', _f);

			if (!isNil(this.parentType)) formData.append('parentType', this.parentType);
			if (!isNil(this.parentSubType)) formData.append('parentSubType', this.parentSubType);
			if (!isNil(this.parentId)) formData.append('parentId', this.parentId);

			formData.append('fileName', this.fileNameGenerated);

			try {
				const savedImg = await this.$axios.$post('upload', formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});
				this.$emit('change', [savedImg]);
			} catch (e) {
				this.createToastForMobile(this.FormMSG(368, 'Error'), this.FormMSG(1225, 'Update image issue'), '', 'danger');
			}
		},
		async handleSavePicture() {
			this.isStartMobilePwaCaptureLoading = true;
			await this.saveImg();
			this.isStartMobilePwaCaptureLoading = false;
			this.isCropModalOpen = false;
		},
		/**
		 * @param {Object} cropPayload
		 */
		handleAutoCrop(cropPayload) {
			this.croppedImg = cropPayload.img;
			this.handleSavePicture();
		},
		generateFilename(extensionFile) {
			return generateSecureId(`filename-id-${makeID(8)}`).substring(0, 24) + '.' + extensionFile;
		}
	}
};
</script>

<style lang="scss" scoped>
.btn-footer-modal {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	.btn:first-child {
		margin-right: 15px;
	}
}
</style>
