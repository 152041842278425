var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "camera-container text-center",
      staticStyle: { cursor: "pointer" },
      on: { click: _vm.startMobilePwaCapture },
    },
    [
      !_vm.useButton
        ? _c("CameraIcon", {
            attrs: { color: "#E2E6EB", size: 25, fill: "#06263E" },
          })
        : _vm._e(),
      _vm.useButton
        ? _c("b-button", { attrs: { variant: "outline-primary" } }, [
            _c("i", { staticClass: "icon-camera" }),
            _vm._v(" " + _vm._s(_vm.labelButton) + " "),
          ])
        : _vm._e(),
      _c(
        "CropCaptureModal",
        _vm._b(
          {
            ref: "myCropPicture",
            attrs: { "base-url-img": _vm.currentImg },
            on: {
              "update:baseUrlImg": function ($event) {
                _vm.currentImg = $event
              },
              "update:base-url-img": function ($event) {
                _vm.currentImg = $event
              },
              cropped: _vm.handleAutoCrop,
              change: _vm.handleAutoCrop,
              close: function ($event) {
                _vm.isCropModalOpen = false
              },
            },
            model: {
              value: _vm.isCropModalOpen,
              callback: function ($$v) {
                _vm.isCropModalOpen = $$v
              },
              expression: "isCropModalOpen",
            },
          },
          "CropCaptureModal",
          _vm.$props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }